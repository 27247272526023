import {SectionHeader} from "../components/SectionHeader";
import {FaCss3Alt, FaGolang, FaHtml5, FaJava, FaJenkins, FaReact} from "react-icons/fa6";
import {
  SiApachekafka, SiAurelia,
  SiDocker,
  SiJavascript,
  SiKubernetes,
  SiMongodb,
  SiNginx,
  SiRabbitmq,
  SiSpring
} from "react-icons/si";
import {BiLogoPostgresql} from "react-icons/bi";
import {DiRedis} from "react-icons/di";
import {GrMysql} from "react-icons/gr";
import './Skills.css';

export function Skills() {
  return (
    <section id="skills" className="element-section" style={{minHeight: "600px"}}>
      <SectionHeader>Skills</SectionHeader>
      <div className="container">
        <div className="row">
          <SkillBox title="Java" borderClasses="b r"><FaJava style={styles.Icon}/></SkillBox>
          <SkillBox title="Spring" borderClasses="b r"><SiSpring style={styles.Icon}/></SkillBox>
          <SkillBox title="Go" borderClasses="b r"><FaGolang style={styles.Icon}/></SkillBox>
          <SkillBox title="React" borderClasses="b r"><FaReact style={styles.Icon}/></SkillBox>
          <SkillBox title="Aurelia" borderClasses="b r"><SiAurelia style={styles.Icon}/></SkillBox>
          <SkillBox title="JavaScript" borderClasses="b"><SiJavascript style={styles.Icon}/></SkillBox>
        </div>
        <div className="row">
          <SkillBox title="HTML5" borderClasses="b r"><FaHtml5 style={styles.Icon}/></SkillBox>
          <SkillBox title="CSS3" borderClasses="b r"><FaCss3Alt style={styles.Icon}/></SkillBox>
          <SkillBox title="RabbitMQ" borderClasses="b r"><SiRabbitmq style={styles.Icon}/></SkillBox>
          <SkillBox title="Kafka" borderClasses="b r"><SiApachekafka style={styles.Icon}/></SkillBox>
          <SkillBox title="PostgreSQL" borderClasses="b r"><BiLogoPostgresql style={styles.Icon}/></SkillBox>
          <SkillBox title="MongoDB" borderClasses="b"><SiMongodb style={styles.Icon}/></SkillBox>
        </div>
        <div className="row">
          <SkillBox title="Redis" borderClasses="r"><DiRedis style={styles.Icon}/></SkillBox>
          <SkillBox title="MySQL" borderClasses="r"><GrMysql style={styles.Icon}/></SkillBox>
          <SkillBox title="Nginx" borderClasses="r"><SiNginx style={styles.Icon}/></SkillBox>
          <SkillBox title="Jenkins" borderClasses="r"><FaJenkins style={styles.Icon}/></SkillBox>
          <SkillBox title="Docker" borderClasses="r"><SiDocker style={styles.Icon}/></SkillBox>
          <SkillBox title="Kubernetes"><SiKubernetes style={styles.Icon}/></SkillBox>
        </div>
      </div>
    </section>
  );
}

const SkillBox = ({borderClasses, title, children}) => {
  return (
    <div className={"col-2 text-center " + (borderClasses ? borderClasses : '')} style={styles.IconContainer}>
      {children}<br/>
      <div style={{marginTop: "0.5rem", fontWeight: "500"}}>{title}</div>
    </div>
  );
};

const styles = {
  IconContainer: {
    padding: "2.5rem 0"
  },
  Icon: {
    fontSize: "2rem"
  }
};

import './NavigationBar.css';

export function NavigationBar() {
  return (
      <section style={styles.headerContainer}>
        <div className="container">
          <div className="row">
            <div className="col-6">
              <div style={styles.title}>
                Sergio Abplanalp
              </div>
            </div>
            <div className="col-6">
              <nav style={{margin: "0", padding: "0"}}>
                <ul style={{margin: "0.2rem", padding: "0"}}>
                  <li className="sa-nav-item"><a href="#about">About</a></li>
                  <li className="sa-nav-item"><a href="#skills">Skills</a></li>
                  <li className="sa-nav-item"><a href="#portfolio">Portfolio</a></li>
                  <li className="sa-nav-item"><a href="#blog">Blog</a></li>
                  <li className="sa-nav-item"><a href="#contact">Contact</a></li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </section>
  );
}

const styles = {
  headerContainer: {
    position: "fixed",
    top: "0",
    width: "100%",
    backgroundColor: "#fff",
    boxShadow: "0 3px 15px rgba(0,0,0,.2)"
  },
  title: {
    padding: "2rem 0",
    color: "#76ce33",
    textTransform: "uppercase",
    fontFamily: "'Poppins', sans-serif",
    fontSize: "20px",
    fontWeight: "600"
  }
};
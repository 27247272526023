export function Portfolio() {
  return (
    <section id="portfolio" className="element-section" style={{minHeight: "600px", backgroundColor: "#91d85c"}}>
      <div className="text-center">
        <h2 style={{borderBottom: "1px solid #6ab92e", color: "#3b671a"}}>Some of my work</h2>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-6 text-center">
            <h5 style={{color: "#2f5214"}}>Goodwood Baptist FC</h5>
            <a href="https://www.gbfc.co.za" target="_blank" rel="noreferrer">
              <img src="/images/gbfc.png" alt="GBFC"/>
            </a>
          </div>
          <div className="col-6 text-center">
            <h5 style={{color: "#2f5214"}}>First Class Services</h5>
            <a href="https://www.firstclassservices.co.za" target="_blank" rel="noreferrer">
              <img src="/images/first-class-services.png" alt="First Class Services"/>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

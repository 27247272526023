import {SectionHeader} from "../components/SectionHeader";
import {GrGithub} from "react-icons/gr";
import {FaLinkedinIn} from "react-icons/fa6";
import {Button} from "react-bootstrap";
import {useState} from "react";
import {toast} from "react-toastify";
import './Contact.css';
import 'react-toastify/dist/ReactToastify.css';

export function Contact() {
  const [name, setName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');

  const sendMessage = () => {
    if (!validRequest) {
      toast.error('Name, email address and message are required fields.', { theme: 'colored' });
      return;
    }

    const request = {name, emailAddress, subject, content};
    const options = {
      method: 'POST',
      body: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json'
      }
    };
    fetch(`/messages`, options)
      .then(() => {
        toast.success("Message sent!", { theme: 'colored' })
        setName('');
        setEmailAddress('');
        setSubject('');
        setContent('');
      });
  };

  const validRequest = name !== '' && emailAddress !== '' && content !== '';

  return (
    <section id="contact" className="element-section" style={{backgroundColor: "#f9fbfd", borderBottom: "0"}}>
      <SectionHeader>Let's connect</SectionHeader>
      <div className="container">
        <div className="row">
          <div className="col-5 offset-1">
            <div className="container-fluid">
              <div className="row" style={{marginBottom: "1.5rem"}}>
                <div className="col-6">
                  <input placeholder="Name*" className="contact-form-element"
                         value={name}
                         onChange={e => setName(e.target.value)}/>
                </div>
                <div className="col-6">
                  <input placeholder="Email*" className="contact-form-element"
                         value={emailAddress}
                         onChange={e => setEmailAddress(e.target.value)}/>
                </div>
              </div>
              <div className="row" style={{marginBottom: "1.5rem"}}>
                <div className="col-12">
                  <input placeholder="Subject" className="contact-form-element"
                         value={subject}
                         onChange={e => setSubject(e.target.value)}/>
                </div>
              </div>
              <div className="row" style={{marginBottom: "1.5rem"}}>
                <div className="col-12">
                  <textarea placeholder="Message*" className="contact-form-element contact-form-textarea"
                            value={content}
                            onChange={e => setContent(e.target.value)}></textarea>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Button className="sa-button" style={{width: "100%", padding: "0.8rem 0"}}
                          onClick={sendMessage}>Send</Button>
                </div>
              </div>
            </div>

          </div>
          <div className="col-4 offset-1">
            <h4>Sergio Abplanalp</h4>
            <p>Software engineer</p><br/>

            <p style={{fontWeight: "500"}}>Email</p>
            <p style={{color: "#999fb3"}}>hello@sergioabplanalp.com</p>
            <p style={{fontWeight: "500"}}>Website</p>
            <p style={{color: "#999fb3"}}>www.sergioabplanalp.com</p><br/>

            <a href="https://www.linkedin.com/in/sergioabplanalp/" target="_blank" rel="noreferrer">
              <FaLinkedinIn className="contact-icon"/>&nbsp;&nbsp;&nbsp;&nbsp;
            </a>
            <a href="https://github.com/sergioabplanalp" target="_blank" rel="noreferrer">
              <GrGithub className="contact-icon"/>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

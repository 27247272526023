export function Footer() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 text-center" style={{padding: "2.5rem 0"}}>
          <span style={{fontSize: "0.9rem", color: "#999fb3"}}>&copy; Copyright Sergio Abplanalp.</span>
        </div>
      </div>
    </div>
  );
}
import {SectionHeader} from "../components/SectionHeader";

export function About() {
  return (
      <section id="about" className="element-section" style={{marginTop: "6rem"}}>
        <SectionHeader>About Me</SectionHeader>
        <div className="container">
          <div className="row">
            <div className="col-6 offset-1" style={{color: "#999fb3", fontWeight: "400", fontSize: "1.3rem"}}>
              <p style={{color: "#3c4258", marginBottom: "2rem", fontWeight: "500"}}>
                I am a Software Engineering professional from Cape Town, South Africa, with a passion for taking innovative
                ideas and turning them into working, real-world solutions.
              </p>
              <p>
                I thrive on solving complex problems with clean, efficient code and believe in a thoughtful, detail-oriented approach to software development.
                I’m always looking to learn and improve my craft, focusing on building systems that are not just functional but also maintainable and scalable.
              </p>
            </div>
            <div className="col-3 offset-1">
              <img src="/profile.jpg" alt="profile"
                   style={{borderRadius: "2px", boxShadow: "0 5px 20px rgba(14,25,80,.30)", width: "100%"}}/>
            </div>
          </div>
        </div>
      </section>
  );
}

import {SectionHeader} from "../components/SectionHeader";
import {useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import './Blog.css';

export function Blog() {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    fetch(`https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@sergioabplanalp`)
      .then(response => response.json())
      .then(response => {
        const a = response.items.map(item => {
          return {
            id: item.guid,
            title: item.title,
            content: item.content.replaceAll(/<[^>]*>/g, '').substring(0, 200),
            publishedDate: item.pubDate,
            categories: item.categories
          };
        });
        setArticles(a);
      });
  }, []);

  return (
    <section id="blog" className="element-section" hidden={articles.length === 0}>
      <SectionHeader>Latest articles</SectionHeader>
      <div className="container">
        <div className="row">
          {articles.map((article, idx) => {
            return (
              <div className="col-6" key={idx}>
                <div>
                  <h5>{article.title}</h5>
                  <span dangerouslySetInnerHTML={{__html: article.content.substring(0, 200)}}></span>...
                  <br/>
                  <a href="https://google.com" className="read-more-link">Read more</a>
                  {idx < articles.length - 2 && <span><br/><br/><br/><br/><br/></span>}
                </div>
              </div>
            );
          })}
        </div>
        <div className="row">
          <div className="col-12 text-center" style={{marginTop: "3rem"}}>
            <Button className="sa-button" style={{margin: "1rem 2rem 0 0", padding: "0.8rem 4rem"}}>View all articles</Button>
          </div>
        </div>
      </div>
    </section>
  );
}

import {ToastContainer} from "react-toastify";
import {NavigationBar} from "./sections/NavigationBar";
import {About} from "./sections/About";
import {Skills} from "./sections/Skills";
import {Portfolio} from "./sections/Portfolio";
import {Blog} from "./sections/Blog";
import {Contact} from "./sections/Contact";
import {Footer} from "./sections/Footer";
import './App.css';

function App() {
  return (
    <>
      <ToastContainer position="top-center" pauseOnHover={false} />
      <NavigationBar/>
      <About/>
      <Skills/>
      <Portfolio/>
      <Blog/>
      <Contact/>
      <Footer/>
    </>
  );
}

export default App;
